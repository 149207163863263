import React from 'react';
import s from './BannersForFestivalMobile.module.scss';
import { isIOS } from 'actions/referrer';
import { isMac } from 'actions/referrer';
const BannersForFestivalMobile = ({ mainTitle = 'Christmas', days = '7', off = '50', mCenter = null }) => {
  return (
    <div className={s.banner}>
      <div className={s.wrap}>
        <h2 className={s.mainTitle}>Special {mainTitle} offer</h2>
        <p className={s.desc}>
          Free IELTS Classes + <span>{off}% </span> OFF On All Premium Plans for <span>{days}</span> Days!
        </p>
        <img className={s.photo} src={mCenter} alt={mainTitle} />
        <div className={s.action}>
          {isIOS() || isMac() ? (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/in/app/kandor-ielts/id6446410798"
              className={` ${s.button}`}>
              Download App
            </a>
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.trykandor.twa"
              className={` ${s.button}`}>
              Download App
            </a>
          )}
        </div>
        <p className={s.subDesc}>Celebrate With Kandor Family!</p>
      </div>
    </div>
  );
};

export default BannersForFestivalMobile;
