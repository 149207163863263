import ChristmasBg from '../../assets/img/Festival/christmas.png';
import NewYearsBg from '../../assets/img/Festival/new.png';
import SaluteTitle from '../../assets/img/Festival/salute.png';
import EmblemTopPhoto from '../../assets/img/Festival/State Emblem of India.png';
import StateBg from '../../assets/img/Festival/topState.png';
import centerV from '../../assets/img/Festival/centerV.png';
import DiwaliBg from '../../assets/img/Festival/diwali.png';
import RamadanCenter from '../../assets/img/Festival/ramadanCenter.png';
import HolliBg from '../../assets/img/Festival/holliBg.png';
import HolliCenter from '../../assets/img/Festival/holliCenter.png';
import mCenterCh from '../../assets/img/Festival/mobileC.png';
import mCenterN from '../../assets/img/Festival/newM.png';
import mCenterD from '../../assets/img/Festival/Diya.png';
import mCenterV from '../../assets/img/Festival/mV.png';
import mCenterDw from '../../assets/img/Festival/mD.png';

export const monthlyData = {
  0: { mainTitle: 'New Year', bg: NewYearsBg, titlePhoto: SaluteTitle, mCenter: mCenterN },
  1: { mainTitle: 'Valentine’s Day', centerPhoto: centerV, mCenter: mCenterV },
  2: { mainTitle: 'Holi', bg: HolliBg, centerPhoto: HolliCenter, mCenter: HolliCenter },
  4: { mainTitle: 'Ramadan', centerPhoto: RamadanCenter, mCenter: RamadanCenter },
  10: { mainTitle: 'Diwali', bg: DiwaliBg, mCenter: mCenterDw },
  11: { mainTitle: 'Christmas', bg: ChristmasBg, mCenter: mCenterCh },
};
