import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { ErrorBoundary } from "react-error-boundary";

import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorkerRegistration";
import { firebaseInit } from "./firebase/firebaseInit";
import setupInterceptors from "./services/setupInterceptors";
import mixpanel from "mixpanel-browser";
// import clevertap from 'clevertap-web-sdk';
//TODO: used for 1% on landing page
import "assets/scss/black-dashboard-pro-react.scss";

import { Router } from "react-router-dom";
import ErrorFallback from "pages/ErrorFallback";
import AppRouter from "./routers/AppRouter";

import("react-ga").then(ReactGA => {
  ReactGA.initialize("G-5P7VQE22R7");
  const history = createBrowserHistory();
  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
});

// Moving to Admin to save cost
/*
clevertap.init('6K9-5Z8-9W7Z');
clevertap.privacy.push({optOut: false});
clevertap.privacy.push({useIP: true});
*/

mixpanel.init(
  "09e88311c11dd3b98d16afe3f872a550",
  {
    api_host: "https://api-eu.mixpanel.com",
    debug: process.env.NODE_ENV === "development",
    record_sessions_percent: 40
  },
  ""
);

mixpanel.register_once({ version: process.env.REACT_APP_VERSION });

const history = createBrowserHistory();

const store = configureStore();

setupInterceptors(store);
const jsx = (
  <Suspense fallback={<></>}>
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AppRouter />
        </ErrorBoundary>
      </Router>
    </Provider>
  </Suspense>
);

let hasRendered = false;

const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"));
    hasRendered = true;
  }
};

renderApp();

serviceWorker.register();
firebaseInit(store);
