import React from 'react';
import s from './BannersForFestival.module.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isMac } from 'actions/referrer';
import { isIOS } from 'actions/referrer';
const BannersForFestival = ({
  mainTitle = 'Christmas',
  days = '7',
  off = '50',
  bg = null,
  topPhoto = null,
  centerPhoto = null,
  titlePhoto = null,
}) => {
  console.log('titlePhoto', titlePhoto);

  const history = useHistory();
  const goToLogin = () => {
    history.push('/signup');
  };
  return (
    <div className={s.banner}>
      {bg && (
        <img style={mainTitle === 'Republic Day Offer' ? { top: 0 } : {}} alt={mainTitle} className={s.bg} src={bg} />
      )}
      <div className={s.wrap}>
        {topPhoto && <img className={s.topImg} src={topPhoto} />}
        {titlePhoto && <img className={s.titleImg} src={titlePhoto} />}
        <h2 className={s.mainTitle}>Special {mainTitle} offer</h2>
        <p className={s.desc}>
          Free IELTS Classes + <span>{off}% </span> OFF On All Premium Plans for <span>{days}</span> Days!
        </p>

        {centerPhoto && (
          <img
            style={mainTitle === 'Valentine’s Day' ? { marginBottom: '10px' } : {}}
            alt={mainTitle}
            className={s.centerImg}
            src={centerPhoto}
          />
        )}

        <div className={s.action}>
          <button onClick={() => goToLogin()} className={`${s.sign} ${s.button}`}>
            Sign up
          </button>
          {isIOS() || isMac() ? (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/in/app/kandor-ielts/id6446410798"
              className={`${s.download} ${s.button}`}>
              Download App
            </a>
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.trykandor.twa"
              className={`${s.download} ${s.button}`}>
              Download App
            </a>
          )}
        </div>
        <p className={s.subDesc}>Celebrate With Kandor Family!</p>
      </div>
    </div>
  );
};

export default BannersForFestival;
